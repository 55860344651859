import _ from 'lodash';
import { FETCH_VIB_RESTAURANTS } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_VIB_RESTAURANTS:
      return action.payload

    default:
      return state;
  }
};
