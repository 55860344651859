import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import RestaurantList from "./RestaurantList";
import { fetchVibRestaurants } from "../../actions";
import RestaurantFilter from './RestaurantFilter'
import Pagination from './Pagination'
import { withRouter } from 'react-router-dom';
import SearchResults from './SearchResults'

class Restaurants extends Component {

  state = {
    filteredRestaurants: null
  }

  componentDidMount() {
    this.fetchRestaurants();
  }

  componentDidUpdate(prevProps) {
    const prevQueryString = prevProps.location.search;
    const currentQueryString = this.props.location.search;

    if (prevQueryString !== currentQueryString) {
      this.fetchRestaurants();
    }
  }

  fetchRestaurants = () => {
    this.props.fetchVibRestaurants()
    const element = document.querySelector('.content-title');
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the element
      }, 300);
    } 
  }

  applyFilter = ({province, searchTerm}) => {

     // Get the current URL and its search parameters
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    searchParams.set('page', 1);

    // Set or update the new parameters
    if (searchTerm) {
      searchParams.set('search', searchTerm);
    } else {
      searchParams.delete('search');
    }
    if (province) {
      searchParams.set('province', province);
    } else {
      searchParams.delete('province');
    }

    // Construct the new URL with updated query string
    const newUrl = `${url.pathname}?${searchParams.toString()}`;
    
    // Optionally navigate to the new URL or use it as needed
    this.props.history.push(newUrl);
  }

  render() {
    
    return (

      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`FAT bastard wines | VIB | Restaurants `}</title>
          <meta
            name="description"
            content="To LIVE LARGE, you need to gallop to your local watering hole and take a chunk-size bite out of life. We unreservedly encourage you to try one of our VIB restaurants."
          />
          <link rel="canonical" href={window.location} />
        </Helmet>
        <RestaurantFilter onSubmit={this.applyFilter} />
        <SearchResults data={this.props.restaurants} />
        <RestaurantList
          restaurants={this.props.restaurants.data}
        />
        <Pagination data={this.props.restaurants} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    restaurants: state.vibRestaurants
  };
};

export default connect(
  mapStateToProps,
  {
    fetchVibRestaurants
  }
)(withRouter(Restaurants));
