import {
  SIGN_IN,
  SIGN_OUT,
  FETCH_PAGE,
  FETCH_PAGES,
  FETCH_GALLERY,
  CLEAR_GALLERY,
  FETCH_PAGE_GALLERY,
  FETCH_VIDEO,
  FETCH_WINES,
  FETCH_WINE,
  FETCH_RECIPES,
  FETCH_RECIPE,
  FETCH_SOCIAL_FEED,
  UPDATE_SOCIAL_FEED,
  VERIFY_AGE,
  FETCH_RESTAURANTS,
  FETCH_RESTAURANT,
  FETCH_COUNTRIES,
  FETCH_PROVINCES,
  FETCH_CITIES,
  REGISTER,
  VERIFY_DISCLAIMER,
  CREATE_ENTRY,
  CREATE_ENTRY_FAILED,
  FETCH_VIB_RESTAURANTS
} from "./types";
import { fatBastard, world, campaign, vibRestaurants } from "../apis/fatBastard";
import flocker from "../apis/flocker";

export const createEntry = formValues => async dispatch => {

    try{

      const response = await campaign.post('fatbastard/fathersday', formValues)

      dispatch({
          type: CREATE_ENTRY,
          payload: {
              data: response.data
          }
      })

      window.location = '/fathersday/thankyou'

    } catch(error){
        dispatch({
          type: CREATE_ENTRY_FAILED,
          payload: {
              data: error.response.data
          }
        })
    }
}

export const veryfyDisclaimer = response => async dispatch => {
  dispatch({
    type: VERIFY_DISCLAIMER,
    payload: response
  })
}

export const signIn = userId => {
  return {
    type: SIGN_IN,
    payload: userId
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT
  };
};

export const fetchPage = url => async dispatch => {
  const response = await fatBastard.get(`/pages/${url}`);

  dispatch({
    type: FETCH_PAGE,
    payload: response.data
  });
};

export const fetchPages = () => async dispatch => {
  const response = await fatBastard.get(`/pages`);

  dispatch({
    type: FETCH_PAGES,
    payload: response.data
  });
};

export const fetchGallery = (id, type) => async dispatch => {
  const response = await fatBastard.get(`/galleries/${id}/${type}`);

  dispatch({
    type: FETCH_GALLERY,
    payload: response.data
  });
};

export const clearGallery = (id, type) => async dispatch => {
  dispatch({
    type: CLEAR_GALLERY
  });
};

export const fetchPageGallery = (id, type) => async dispatch => {
  const response = await fatBastard.get(`/galleries/${id}/${type}`);

  dispatch({
    type: FETCH_PAGE_GALLERY,
    payload: response.data
  });
};

export const fetchRecipes = () => async dispatch => {
  const response = await fatBastard.get(`/recipes`);

  dispatch({
    type: FETCH_RECIPES,
    payload: response.data.data
  });
};

export const fetchRecipe = slug => async dispatch => {
  const response = await fatBastard.get(`/recipes/${slug}`);
  dispatch({
    type: FETCH_RECIPE,
    payload: response.data.data
  });
};

export const fetchRestaurants = () => async dispatch => {

  const queryString = window.location.search

  const response = await fatBastard.get(`/restaurants${queryString !== '' ? queryString : ''}`);
  
  dispatch({
    type: FETCH_RESTAURANTS,
    payload: response.data
  });
};

export const fetchRestaurant = slug => async dispatch => {
  const response = await fatBastard.get(`/restaurants/${slug}`);

  dispatch({
    type: FETCH_RESTAURANT,
    payload: response.data.data
  });
};

export const fetchWine = slug => async dispatch => {
  const response = await fatBastard.get(`/wines/${slug}`);

  dispatch({
    type: FETCH_WINE,
    payload: response.data.data
  });
};

export const fetchWines = () => async dispatch => {
  const response = await fatBastard.get(`/wines`);
  const page = await fatBastard.get(`/pages/the-fat-bastards`);

  const wines = {
    items: response.data.data,
    page: page.data,
    selectedWine: {}
  };

  dispatch({
    type: FETCH_WINES,
    payload: wines
  });
};

export const fetchVideo = id => async dispatch => {
  const response = await fatBastard.get(`/videos/${id}`);

  dispatch({
    type: FETCH_VIDEO,
    payload: response.data.data
  });
};

export const sendMail = formValues => async dispatch => {
  const response = await fatBastard.post(`/general-enquiry`, formValues);

  return response.data;
};

export const fetchSocialFeed = () => async dispatch => {
  const response = await flocker.get(
    `https://api.flockler.com/v1/sites/8286/articles`,
    {
      params: {
        count: 8
      }
    }
  );

  dispatch({
    type: FETCH_SOCIAL_FEED,
    payload: response.data
  });
};

export const updateSocialFeed = url => async dispatch => {
  const response = await flocker.get(`${url}`);

  dispatch({
    type: UPDATE_SOCIAL_FEED,
    payload: response.data
  });
};

export const verifyAge = response => async dispatch => {
  dispatch({
    type: VERIFY_AGE,
    payload: response
  });
};

export const fetchCountries = type => async dispatch => {
  return world.get(`/${type}`).then(res => {
    dispatch({
      type: FETCH_COUNTRIES,
      payload: res.data.countries
    });
  });
};

export const fetchProvinces = (type, id) => async dispatch => {
  return world.get(`/${type}/${id}`).then(res => {
    dispatch({
      type: FETCH_PROVINCES,
      payload: res.data.regions
    });
  });
};

export const fetchCities = (type, id) => async dispatch => {
  return world.get(`/${type}/${id}`).then(res => {
    dispatch({
      type: FETCH_CITIES,
      payload: res.data.cities
    });
  });
};

export const registerUser = formalues => async dispatch => {
  const response = await fatBastard.post('/vib-register', formalues)

  dispatch({
    type: REGISTER,
    payload: response.data
  });
  
};

export const fetchVibRestaurants = () => async dispatch => {

  const queryString = window.location.search

  const response = await vibRestaurants.get(`/restaurants${queryString !== '' ? queryString : ''}`);
  
  dispatch({
    type: FETCH_VIB_RESTAURANTS,
    payload: response.data
  });
};