import React, {useRef} from 'react'
import './RestaurantFilter.scss';

export default function RestaurantFilter(props) {

    const provinceRef = useRef(null);
    const searchRef = useRef(null);

    const handleOnSubmit = () => {
        
        const province = provinceRef.current.value
        const searchTerm = searchRef.current.value

        props.onSubmit({
            province, 
            searchTerm
        })
    }

    const reset = () => {
        searchRef.current.value = ''
        provinceRef.current.value = ''
    }

  return (
    <div className='_advanced-filter'>
        <div className='_region'>
            <select name="province" id="province" ref={provinceRef}>
                <option value="">Select a region</option>
                <option value="">All</option>
                <option>Eastern Cape</option>
                <option>Free State</option>
                <option>Gauteng</option>
                <option>KwaZulu-Natal</option>
                <option>Limpopo</option>
                <option>Mpumalanga</option>
                <option>North West</option>
                <option>Northern Cape</option>
                <option>Western Cape</option>
            </select>
        </div>
        <div className='_search'>
            <div className='_icon'>
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5z"/></svg>
            </div>
            <input type="text" name="search" placeholder='Search restaurants'  ref={searchRef} />
            <button className='_reset' onClick={reset}>
                <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/></svg>
            </button>
        </div>
        <button className='btn _apply-btn' onClick={handleOnSubmit}>Apply Filter</button>
    </div>
  )
}
