import "./Slider.scss";
import React from "react";
import Slider from "react-slick";
import { Tween } from "react-gsap";
import { Link } from "react-router-dom";
// import ReactHtmlParser from "react-html-parser";
// import ButtonRight from "../buttons/ButtonRight";
import backgroundImageBanner from '../../resources/img/gold-banner@2x.png'
// import merlotBackgroundImage from '../../resources/img/merlot-background-banner@2x.jpg'
// import merlotBottle from '../../resources/img/free-and-fast-delivery-fat-bastard-merlot@2x.png'
// import whiteWineSale from '../../resources/img/white-wine-sale.png'
// import intchar from '../../resources/img/20-percent-off-chardonnay-feature@2x.png'
// import backgroundImageBanner1 from '../../resources/img/landing-page-banner/winder-wonderland-background.jpg'
import bannerImage from '../../resources/img/Free-and-Fast-Delivery@2x.png'
// import bannerImage1 from '../../resources/img/landing-page-banner/winter-wonderland-hero@2x.png'
// import winterImg from '../../resources/img/landing-page-banner/winter-wonderland@2x.png'
// import bannerImage2 from '../../resources/img/live-large-this-may@2x.png'
// import drinkWineDay from '../../resources/img/drinkwineday-hero@2x.png'
import bannerImage3 from '../../resources/img/2l-slim-box-banner.png'
// import bannerImage4 from '../../resources/img/chef-ollie@2x.jpg'
// import bannerImage5 from '../../resources/img/ENTER-NOW@2x.jpg'
// import bannerImage6 from '../../resources/img/christmas-in-july@2x.jpg'
// import enjoyImage from '../../resources/img/20-percent-off@3x.png'
import merlotDaySpecial from '../../resources/img/merlot-day-special@2x.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import './banner.scss';

//https://github.com/akiran/react-slick

class HomeSlider extends React.Component {
  imageSwap = (imagePAth, alt) => {
    if (window.innerWidth > 800) {
      return;
    } else {
      return backgroundImageBanner;
    }
  };

  render() {
    var settings = {
      infinite: true,
      speed: 500,
      autoplaySpeed: 4000000,
      slidesToShow: 1,
      fade: true,
      autoplay: true,
    };

    if (window.innerHeight > 800) {
      settings.dots = false;
      settings.arrows = true;
    } else {
      settings.dots = true;
      settings.arrows = false;
    }

    return (
      <div className="home-slider">
        <Slider {...settings}>

           {/*<div className="sliderItem">
            <div
              className="_merlot-banner"
              style={{backgroundImage: `url(${backgroundImageBanner})` }}
            >
              <Tween
                from={{ opacity: 0, rotation: -10 }}
                delay=".7"
                duration=".7"
                ease="Circ.easeOut"
              >
                <img
                  className="_merlot-bottle"
                  src={enjoyImage}
                  alt=""
                />
              </Tween>
              <div className="ui container" style={{ height: "100%" }}>
                <header className="sliderHeader sliderHeader-merlot">
                  <Tween
                    from={{ y: -20, opacity: 0 }}
                    delay=".2"
                    duration=".7"
                    ease="Circ.easeOut"
                  >
                  </Tween>

                  <Tween
                    from={{ x: -20, opacity: 0 }}
                    delay=".4"
                    duration=".7"
                    ease="Circ.easeOut"
                  >
                    <h2 className="_merlot-heading">
                      <strong>ImPOURtant<br />Announcement!</strong>
                    </h2>
                    <p className="_merlot-message">Save <strong>BIG</strong> and celebrate <strong>#MerlotDay</strong> all week with <strong>20% OFF</strong> my fabulously full-bodied <strong>FAT <i>bastard</i> Merlot</strong>.</p>
                  </Tween>
                </header>

                <Tween
                  from={{ x: 30, opacity: 0 }}
                  delay=".4"
                  duration=".7"
                  ease="Circ.easeOut"
                >
                </Tween>

                <div className="sliderImageOverlay">
                  <div className="inner">
                    
                    <Tween
                      from={{ x: 50, opacity: 0 }}
                      to={{ x: "-20%", opacity: 1 }}
                      delay=".9"
                      duration="1"
                      ease="Circ.easeOut"
                    >
                      
                    </Tween>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="ui container">
              <Tween
                from={{ y: 20, opacity: 0 }}
                duration=".7"
                delay=".7"
                ease="Circ.easeOut"
              >
                <div>
                  
                  <a href="https://shop.fatbastardwine.co.za/?product=merlot" className="btnRight purple">
                    <div className="inner">
                      <span className="button-left">SHOP NOW </span>
                      <span className="button-right">                  
                        <FontAwesomeIcon icon={faArrowRight} />
                      </span>
                    </div>
                  </a>
                </div>
              </Tween>
            </div>
          </div>*/} 

        

          <div className="sliderItem">
            <div
              style={{
                backgroundImage: `url(${backgroundImageBanner})`,
                backgroundPosition: "right center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "100%"
              }}
            >
              <div className="ui container" style={{ height: "100%" }}>
                <header className="sliderHeader">
                  <Tween
                    from={{ x: -20, opacity: 0 }}
                    delay=".4"
                    duration=".7"
                    ease="Circ.easeOut"
                  >
                    <div
                      style={{
                        opacity: '1',
                        transform: 'matrix(1, 0, 0, 1, 0, 0)'
                      }}
                      className="_dynamic-heading"
                    >
                    <h2 className="_two" style={{margin: '20px 0 0 0', fontSize: '2.5em'}}>
                     For the Love of Merlot:<br />
                     Enjoy <strong>20% Off</strong> our <strong>F<i>b</i> Merlot</strong>
                    </h2>
                    {/* <h2 className="_two" style={{margin: '10px 0 0 0', fontSize: '1.6em'}}>
                      Enjoy 20% Off All <strong>FAT <i>bastard</i></strong> Wines
                    </h2>
                    <h2 className="_two" style={{margin: '10px 0 0 0', fontSize: '1.2em'}}>
                      Use coupon code <strong>XMASINJULY</strong> upon checkout.
                    </h2> */}
                    <p className="_one" style={{margin: '20px 0 0 0', fontWeight:'500', fontSize: '0.9em'}}>
                      {/* <strong><i>Offer valid from 8<sup>th</sup> - 31<sup>st</sup> July.</i></strong> */}
                      <strong><i>Offer valid from 07 – 10 November. | T’s & C’s apply.</i></strong>
                    </p>

                    </div>
                  </Tween>
                </header>

                <Tween
                  from={{ x: 30, opacity: 0 }}
                  delay=".4"
                  duration=".7"
                  ease="Circ.easeOut"
                >
                </Tween>

                <div className="sliderImageOverlay sliderImageOverlay_uranus sliderImageOverlay_uranus_top" style={{ width: "480px" }}>
                  <div className="inner">
                    <Tween
                      from={{ opacity: 0, rotation: -10 }}
                      delay=".7"
                      duration=".7"
                      ease="Circ.easeOut"
                    >
                      <img
                        // className="whiteWineSale"
                        style={{maxWidth: '100%'}}
                        src={merlotDaySpecial}
                        alt="International ChardonnayDay"
                      />
                    </Tween>
                    <Tween
                      from={{ x: 50, opacity: 0 }}
                      to={{ x: "-20%", opacity: 1 }}
                      delay=".9"
                      duration="1"
                      ease="Circ.easeOut"
                    >
                      
                    </Tween>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="ui container">
              <Tween
                from={{ y: 20, opacity: 0 }}
                duration=".7"
                delay=".7"
                ease="Circ.easeOut"
              >
                <div>
                  
                  <a href="https://shop.fatbastardwine.co.za/?product=merlot" className="btnRight purple">
                    <div className="inner">
                      <span className="button-left">SHOP NOW </span>
                      <span className="button-right">                  
                        <FontAwesomeIcon icon={faArrowRight} />
                      </span>
                    </div>
                  </a>
                </div>
              </Tween>
            </div>
          </div>

          {/*<div className="sliderItem">
            <div
              style={{
                backgroundImage: `url(${backgroundImageBanner})`,
                backgroundPosition: "right center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "100%",
              }}
            >
              <div className="ui container" style={{ height: "100%" }}>
                <header className="sliderHeader">
                  <Tween
                    from={{ x: -20, opacity: 0 }}
                    delay=".4"
                    duration=".7"
                    ease="Circ.easeOut"
                  >
                  <div
                      style={{
                        opacity: '1',
                        transform: 'matrix(1, 0, 0, 1, 0, 0)'
                      }}
                      className="_dynamic-heading"
                    >
                    <h2 className="_two" style={{fontSize: '1.72em'}}>
                      Send your favourite <strong>F<i>b</i> Chardonnay</strong><br /> 
                      inspired recipe to <a href="mailto:food@sundaytimes.co.za?subject=Fb%20Chardonnay%20inspired%20recipe" style={{ color: '#000'}}>food@sundaytimes.co.za</a><br /> 
                      and <strong>stand a chance to WIN</strong> a Chardonnay<br /> 
                      hamper worth over R7500 with <strong>FAT<br /> 
                      <i>bastard</i></strong>, Le Creuset & Zola Nene.
                    </h2>
                    </div>
                  </Tween>
                </header>

                <Tween
                  from={{ x: 30, opacity: 0 }}
                  delay=".4"
                  duration=".7"
                  ease="Circ.easeOut"
                >
                </Tween>

                <div className="sliderImageOverlay sliderImageOverlay_uranus" style={{ width: "508px", right: "0px" }}>
                  <div className="inner">
                    <Tween
                      from={{ opacity: 0, rotation: -10 }}
                      delay=".7"
                      duration=".7"
                      ease="Circ.easeOut"
                    >
                      <img
                        className="uranus"
                        src={bannerImage5}
                        alt=""
                      />
                    </Tween>
                    <Tween
                      from={{ x: 50, opacity: 0 }}
                      to={{ x: "-20%", opacity: 1 }}
                      delay=".9"
                      duration="1"
                      ease="Circ.easeOut"
                    >
                      
                    </Tween>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="ui container">
              <Tween
                from={{ y: 20, opacity: 0 }}
                duration=".7"
                delay=".7"
                ease="Circ.easeOut"
              >
                <div>
                  <a href="mailto:food@sundaytimes.co.za?subject=Fb%20Chardonnay%20inspired%20recipe" className="btnRight purple">
                    <div className="inner">
                      <span className="button-left">ENTER NOW </span>
                      <span className="button-right">                  
                        <FontAwesomeIcon icon={faArrowRight} />
                      </span>
                    </div>
                  </a>
                </div>
              </Tween>
            </div>
          </div> */}


          {/*<div className="sliderItem">
            <div
              style={{
                backgroundImage: `url(${backgroundImageBanner})`,
                backgroundPosition: "right center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "100%"
              }}
            >
              <div className="ui container" style={{ height: "100%" }}>
                <header className="sliderHeader">
                  <Tween
                    from={{ x: -20, opacity: 0 }}
                    delay=".4"
                    duration=".7"
                    ease="Circ.easeOut"
                  >
                  <div
                      style={{
                        opacity: '1',
                        transform: 'matrix(1, 0, 0, 1, 0, 0)'
                      }}
                      className="_dynamic-heading"
                    >
                    <h2 className="_two" style={{fontSize: '2.2em'}}>
                    <strong>Enjoy 20% Off FAT <i>bastard</i><br /> 
                    Chardonnay - May 17-31!</strong>
                    </h2>

                    </div>
                  </Tween>
                </header>

                <Tween
                  from={{ x: 30, opacity: 0 }}
                  delay=".4"
                  duration=".7"
                  ease="Circ.easeOut"
                >
                </Tween>

                <div className="sliderImageOverlay sliderImageOverlay_uranus">
                  <div className="inner">
                    <Tween
                      from={{ opacity: 0, rotation: -10 }}
                      delay=".7"
                      duration=".7"
                      ease="Circ.easeOut"
                    >
                      <img
                        className="uranus"
                        src={bannerImage6}
                        alt=""
                      />
                    </Tween>
                    <Tween
                      from={{ x: 50, opacity: 0 }}
                      to={{ x: "-20%", opacity: 1 }}
                      delay=".9"
                      duration="1"
                      ease="Circ.easeOut"
                    >
                      
                    </Tween>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="ui container">
              <Tween
                from={{ y: 20, opacity: 0 }}
                duration=".7"
                delay=".7"
                ease="Circ.easeOut"
              >
                <div>
                  <a href="https://shop.fatbastardwine.co.za/?product=chardonnay" className="btnRight purple">
                    <div className="inner">
                      <span className="button-left">SHOP NOW </span>
                      <span className="button-right">                  
                        <FontAwesomeIcon icon={faArrowRight} />
                      </span>
                    </div>
                  </a>
                </div>
              </Tween>
            </div>
          </div>*/}

          <div className="sliderItem">
            <div
              style={{
                backgroundImage: `url(${backgroundImageBanner})`,
                backgroundPosition: "right center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "100%"
              }}
            >
              <div className="ui container" style={{ height: "100%" }}>
                <header className="sliderHeader">
                  <Tween
                    from={{ x: -20, opacity: 0 }}
                    delay=".4"
                    duration=".7"
                    ease="Circ.easeOut"
                  >
                    <h2
                      style={{
                        opacity: '1',
                        transform: 'matrix(1, 0, 0, 1, 0, 0)'
                      }}
                      className="your_favourite_heading"
                    >
                    <span className="_one">Your favourite <strong>FAT <i>bastard</i></strong> Chardonnay…</span> <br />
                    <span className="_two">Now in a 2L slim box for</span> <br />
                    <span className="_three"><i>larger</i> LIVING</span>
                    </h2>
                  </Tween>
                </header>

                <Tween
                  from={{ x: 30, opacity: 0 }}
                  delay=".4"
                  duration=".7"
                  ease="Circ.easeOut"
                >
                </Tween>

                <div className="sliderImageOverlay sliderImageOverlay_uranus">
                  <div className="inner">
                    <Tween
                      from={{ opacity: 0, rotation: -10 }}
                      delay=".7"
                      duration=".7"
                      ease="Circ.easeOut"
                    >
                      <img
                        className="uranus"
                        src={bannerImage3}
                        alt=""
                      />
                    </Tween>
                    <Tween
                      from={{ x: 50, opacity: 0 }}
                      to={{ x: "-20%", opacity: 1 }}
                      delay=".9"
                      duration="1"
                      ease="Circ.easeOut"
                    >
                      
                    </Tween>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="ui container">
              <Tween
                from={{ y: 20, opacity: 0 }}
                duration=".7"
                delay=".7"
                ease="Circ.easeOut"
              >
                <div>
                  {/* <ButtonRight
                    toUrl="https://shop.fatbastardwine.co.za/"
                    color="yellow"
                    title="SHOP NOW"
                  /> */}
                  <a href="https://shop.fatbastardwine.co.za/?product=2l-bib-chardonnay" className="btnRight purple">
                    <div className="inner">
                      <span className="button-left">SHOP NOW </span>
                      <span className="button-right">                  
                        <FontAwesomeIcon icon={faArrowRight} />
                      </span>
                    </div>
                  </a>
                </div>
              </Tween>
            </div>
          </div>
          

        <div className="sliderItem">
            <div
              style={{
                // background: this.imageSwap(),
                backgroundImage: `url(${backgroundImageBanner})`,
                backgroundPosition: "right center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "100%"
              }}
            >
              <div className="ui container" style={{ height: "100%" }}>
                <header className="sliderHeader">
                  <Tween
                    from={{ x: -20, opacity: 0 }}
                    delay=".4"
                    duration=".7"
                    ease="Circ.easeOut"
                  >
                    <h2
                      style={{
                        opacity: '1',
                        transform: 'matrix(1, 0, 0, 1, 0, 0)'
                      }}
                      className="live_large-heading"
                    >
                    LIVE <i>large</i> and purchase <br />your favourite wines! <br />
                    <strong>Free & Fast Delivery</strong>
                    </h2>
                  </Tween>
                </header>

                <Tween
                  from={{ x: 30, opacity: 0 }}
                  delay=".4"
                  duration=".7"
                  ease="Circ.easeOut"
                >
                </Tween>

                <div className="sliderImageOverlay sliderImageOverlay_uranus">
                  <div className="inner">
                    <Tween
                      from={{ opacity: 0, rotation: -10 }}
                      delay=".7"
                      duration=".7"
                      ease="Circ.easeOut"
                    >
                      <img
                        className="uranus"
                        src={bannerImage}
                        alt=""
                      />
                    </Tween>
                    <Tween
                      from={{ x: 50, opacity: 0 }}
                      to={{ x: "-20%", opacity: 1 }}
                      delay=".9"
                      duration="1"
                      ease="Circ.easeOut"
                    >
                      
                    </Tween>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="ui container">
              <Tween
                from={{ y: 20, opacity: 0 }}
                duration=".7"
                delay=".7"
                ease="Circ.easeOut"
              >
                <div>
                  {/* <ButtonRight
                    toUrl="https://shop.fatbastardwine.co.za/"
                    color="yellow"
                    title="SHOP NOW"
                  /> */}
                  <a href="https://shop.fatbastardwine.co.za" className="btnRight purple">
                    <div className="inner">
                      <span className="button-left">SHOP NOW </span>
                      <span className="button-right">                  
                        <FontAwesomeIcon icon={faArrowRight} />
                      </span>
                    </div>
                  </a>
                </div>
              </Tween>
            </div>
          </div>

          
          

          {/* <div className="sliderItem"  >
            <div
              style={{
                // background: 'url("../../resources/Header@2x.jpg")',
                backgroundImage: `url(${backgroundImageBanner})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: "100%"
              }}
            >
              <div className="ui container" style={{ height: "100%" }}>
                <header className="sliderHeader">
                  <Tween
                    from={{ y: -20, opacity: 0 }}
                    delay=".2"
                    duration=".7"
                    ease="Circ.easeOut"
                  >
                    <div className="slider-product-name">
                    <span style={{color: '#5B2B81', fontSize: '30px'}}>Shop Online Today!</span>
                    </div>
                  </Tween>

                  <Tween
                    from={{ x: -20, opacity: 0 }}
                    delay=".4"
                    duration=".7"
                    ease="Circ.easeOut"
                  >
                    {ReactHtmlParser(
                      `<h2 style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">CLICK, SHOP, <br /> SIP & SAVOUR<br />
                      </h2>`
                    )}
                  </Tween>
                </header>

                <Tween
                  from={{ x: 30, opacity: 0 }}
                  delay=".4"
                  duration=".7"
                  ease="Circ.easeOut"
                >
                </Tween>

                <div className="sliderImageOverlay">
                  <div className="inner">
                    <Tween
                      from={{ opacity: 0, rotation: -10 }}
                      delay=".7"
                      duration=".7"
                      ease="Circ.easeOut"
                    >
                      <img
                        className="devices"
                        src={bannerImage2}
                        alt=""
                      />
                    </Tween>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="ui container">
              <Tween
                from={{ y: 20, opacity: 0 }}
                duration=".7"
                delay=".7"
                ease="Circ.easeOut"
              >
                <div>
                  
                  <a href="https://shop.fatbastardwine.co.za" className="btnRight purple">
                    <div className="inner">
                      <span className="button-left">SHOP NOW </span>
                      <span className="button-right">                  
                        <FontAwesomeIcon icon={faArrowRight} />
                      </span>
                    </div>
                  </a>
                </div>
              </Tween>
            </div>
          </div> */}
        
        </Slider>

        
      </div>
    );
  }
}

export default HomeSlider;
