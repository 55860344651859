export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

export const FETCH_PAGE = "FETCH_PAGE";
export const FETCH_PAGES = "FETCH_PAGES";

export const FETCH_WINES = "FETCH_WINES";
export const FETCH_WINE = "FETCH_WINE";

export const FETCH_GALLERY = "FETCH_GALLERY";
export const CLEAR_GALLERY = "CLEAR_GALLERY";

export const FETCH_PAGE_GALLERY = "FETCH_PAGE_GALLERY";

export const FETCH_RECIPES = "FETCH_RECIPES";
export const FETCH_RECIPE = "FETCH_RECIPE";

export const FETCH_RESTAURANTS = "FETCH_RESTAURANTS";
export const FETCH_RESTAURANT = "FETCH_RESTAURANT";

export const FETCH_VIDEO = "FETCH_VIDEO";

export const FETCH_SOCIAL_FEED = "FETCH_SOCIAL_FEED";
export const UPDATE_SOCIAL_FEED = "UPDATE_SOCIAL_FEED";

export const SEND_MAIL = "SEND_MAIL";

export const VERIFY_AGE = "VERIFY_AGE";

export const REGISTER = 'REGISTER'

export const FETCH_COUNTRIES = 'FETCH_COUNTRIES'
export const FETCH_PROVINCES = 'FETCH_PROVINCES'
export const FETCH_CITIES = 'FETCH_CITIES'

export const VERIFY_DISCLAIMER = 'VERIFY_DISCLAIMER'

export const CREATE_ENTRY = 'CREATE_ENTRY'
export const CREATE_ENTRY_FAILED = 'CREATE_ENTRY_FAILED'

export const FETCH_VIB_RESTAURANTS = 'FETCH_VIB_RESTAURANTS'