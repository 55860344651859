import React from 'react'

export default function RestaurantItem({ restaurant }) {

  const renderCultivars = () => {
    const cultivars = restaurant.tags
    return cultivars.map(({name}, index) => {
      return <span key={index} className='_cultivar-tag'>{name}</span>
    })
  }

  return (
    <div className='_restaurant-item'>
      <div className='_heading-container'>
        <h2>{restaurant.restaurant_name}</h2>
        <div className='_buttons'>
          {restaurant.booking_link && restaurant.booking_link !== 'NULL' && (
            <a href={restaurant.booking_link} className='btn _book-here'>BOOK HERE</a>
          )}
          {/* {restaurant.share_link && restaurant.share_link !== 'NULL' && (
            <a href={restaurant.share_link} className='btn _share'>SHARE</a>
          )} */}
        </div>
      </div>
      <div className='_item-content'>
        {restaurant.website && restaurant.website !== 'NULL' && (
          <p><a href={restaurant.website} className='_purple-underline' target="_blank" rel="noreferrer">{restaurant.website}</a></p>
        )}
        <p>
          {restaurant.manager_name && restaurant.manager_name !== 'NULL' && (
            <>{restaurant.manager_name}<span className='_divider' /></>
          )}
          {restaurant.phone && restaurant.phone !== 'NULL' && (
            <><a href={`tel:${restaurant.phone}`}>{restaurant.phone}</a></>
          )}
          {restaurant.email && restaurant.email !== '' && (
            <><span className='_divider' /><a href={`mailto:${restaurant.email}`}>{restaurant.email}</a></>
          )}
        </p>
        <p>
          {
            restaurant.google_maps_url && restaurant.google_maps_url !== '' && restaurant.google_maps_url !== 'NULL' ? (
              <>
                <a href={restaurant.google_maps_url} className='_purple-underline' target="_blank" rel="noreferrer">
                  {restaurant.unit_number && restaurant.unit_number} &nbsp;
                  {restaurant.street_address && restaurant.street_address} &nbsp;
                  {restaurant.suburb && restaurant.suburb} &nbsp;
                  {restaurant.city && restaurant.city} &nbsp;
                  {restaurant.postal_code && restaurant.postal_code}
                </a>
                <span className='_divider' />
              </>
            ) : (
              <>                
                {restaurant.unit_number && restaurant.unit_number} &nbsp;
                {restaurant.street_address && restaurant.street_address} &nbsp;
                {restaurant.suburb && restaurant.suburb} &nbsp;
                {restaurant.city && restaurant.city} &nbsp;
                {restaurant.postal_code && restaurant.postal_code}
                <span className='_divider' />
              </>
            )
          }
          {restaurant.province && restaurant.province !== 'NULL' && restaurant.province }
        </p>
        <p>
          {restaurant.facebook && restaurant.facebook !== '' && (
            <a href={restaurant.facebook} className='_purple-underline' target="_blank" rel="noreferrer">Facebook</a>
          )}
          {restaurant.instagram && restaurant.instagram !== '' && (
            <><span className='_divider' /><a href={restaurant.instagram} className='_purple-underline' target="_blank" rel="noreferrer">Instagram</a></>
          )}
        </p>
        {
          restaurant.tags && restaurant.tags.length > 0 && (
            <>
              <br />
              <p>Wines Available on MENU</p>
              <div className='_wines-avail'>
                {renderCultivars()}
              </div>
            </>
          )
        }
        
      </div>
      
      
      
    </div>
  )
}
