import _ from 'lodash';
import { FETCH_RESTAURANTS, FETCH_RESTAURANT } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_RESTAURANT:
        return {...state, [action.payload.slug]: action.payload};
    case FETCH_RESTAURANTS:
      const newState = {...state, ..._.mapKeys(action.payload.data, 'slug')};
      return newState;

    default:
      return state;
  }
};
