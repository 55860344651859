import _ from 'lodash'
import "./Restaurant.scss";
import React, { Component } from 'react';
import RestaurantItem from './RestaurantItem'
import Loader from '../loader/Loader'

class RestaurantList extends Component {
  renderRestaurants() {
  
    return this.props.restaurants.map(restaurant => {
      if(restaurant !== null){
        return <RestaurantItem key={restaurant.id} restaurant={restaurant}/>
      } else {
        return <></>
      }
    })
  }
  
  render() {
    if(_.isEmpty(this.props.restaurants)){
      return <></>
    }
    
    return (  
      <div>
        <div className="_restaurant-list">
          {this.renderRestaurants()}
        </div>
      </div>
    )
  }
}


export default RestaurantList

